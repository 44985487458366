import "./styles.scss"
import ItemContactar from "../itemContactar"

const Index = () => {

    const data = [
        {
            img: "/images/aliado.jpg",
            title: "SÉ NUESTRO ALIADO",
            url: "",
            txtBtn: "ver requisitos",
        },
        {
            img: "/images/invitar.jpg",
            title: "CONTÁCTANOS",
            url: "",
            txtBtn: "CONTÁCTANOS",
        }
    ]

    return (
        <section className="contactar">
            <div className="sub">
                <h2>{"¿TE GUSTA TRANSPORTES FORZAKARGO?"}</h2>
                <hr></hr>
                <p>{"Te invitamos a ser parte de TRANSPORTES FORZAKARGO SAS, contigo a nuestro lado y tu pasión por lo ue haces ayudaremos a contribuir al progreso de las personas y del país, ¡Juntos somos mas!."}
                </p>
            </div>

            <div className="itemsContainer">
                {
                    data.map((e, i) => {
                        return <>
                            <ItemContactar iterator={i} key={i} data={e} />
                        </>
                    })
                }
            </div>


        </section>
    )
}

export default Index
