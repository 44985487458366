import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Btn from "../Btn/index";
import "./styles.scss";

const Navbar = ({ actualPage }) => {
	const [isOpen, setIsopen] = useState(false);

	useEffect(() => {
		window.onscroll = cambiar_nav;
	}, []);

	const cambiar_nav = () => {
		const nav = document.getElementById("nav");
		const offser_nav = nav.offsetTop;

		if (window.pageYOffset > offser_nav) {
			nav.classList.remove("nav");
			nav.classList.add("nav_with_scroll");
		} else {
			nav.classList.remove("nav_with_scroll");
			nav.classList.add("nav");
		}
	};

	return (
		<nav className="nav" id="nav">
			<div className="menuNormal">
				<Link to={"/"}>
					<img className="img1" alt="LOGO" src="/images/logo-p.png" />
					<img className="img2" alt="LOGO" src="/images/logo-p-blanco.png" />
				</Link>
				<div
					onClick={() => {
						setIsopen(!isOpen);
					}}
				>
					<img alt="LOGO" src="/images/ham2.png" />
				</div>

				<ul>
					<Link
						to={"/"}
						activeStyle
						className={actualPage == "" ? "activeNav" : ""}
					>
						<li>
							inicio
							<hr></hr>
						</li>
					</Link>
					<Link
						to={"/servicios"}
						activeStyle
						className={actualPage == "servicios" ? "activeNav" : ""}
					>
						<li>
							Servicios
							<hr></hr>
						</li>
					</Link>
					<Link
						to={"/oficinas"}
						activeStyle
						className={actualPage == "oficinas" ? "activeNav" : ""}
					>
						<li>
							oficinas
							<hr></hr>
						</li>
					</Link>

					<Link
						to={"/contacto"}
						activeStyle
						className={actualPage == "contacto" ? "activeNav" : ""}
					>
						<li>
							contacto
							<hr></hr>
						</li>
					</Link>

					<Link
						to={"/nosotros"}
						activeStyle
						className={actualPage == "nosotros" ? "activeNav" : ""}
					>
						<li>
							nosotros
							<hr></hr>
						</li>
					</Link>

					<Link
						to={"/aliados"}
						activeStyle
						className={actualPage == "aliados" ? "activeNav" : ""}
					>
						<li>
							aliados
							<hr></hr>
						</li>
					</Link>
				</ul>

				<Link to="contacto">
					<Btn id="btnLogin" disable>
						Contactar
					</Btn>
				</Link>
			</div>

			{isOpen ? (
				<>
					<div className="fondoNegro"></div>
					<div className="menuLateral">
						<ul>

							<li>
								<Link
									to={"/"}
									activeStyle
									className={actualPage == "" ? "activeNav" : ""}
								>
									inicio
								</Link>
							</li>
							<li>
								<Link
									to={"/servicios"}
									activeStyle
									className={actualPage == "servicios" ? "activeNav" : ""}
								>
									Servicios
								</Link>
							</li>
							<li>
								<Link
									to={"/oficinas"}
									activeStyle
									className={actualPage == "oficinas" ? "activeNav" : ""}
								>
									oficinas
								</Link>
							</li>
							<li>
								<Link
									to={"/contacto"}
									activeStyle
									className={actualPage == "contacto" ? "activeNav" : ""}
								>
									contacto
								</Link>
							</li>
							<li>
								<Link
									to={"/nosotros"}
									activeStyle
									className={actualPage == "nosotros" ? "activeNav" : ""}
								>
									nosotros
								</Link>
							</li>
							<li>
								<Link
									to={"/aliados"}
									activeStyle
									className={actualPage == "aliados" ? "activeNav" : ""}
								>
									aliados
								</Link>
							</li>{" "}
							<li>
								<Link to="contacto">
									<Btn id="btnLogin" disable>
										Contactar
									</Btn>
								</Link>
							</li>
						</ul>
					</div>
				</>
			) : (
				""
			)}
		</nav>
	);
};

export default Navbar;
