import "./styles.scss"

const Btn = ({ children, funOnclick, id="", type="btn_1", nombre=""}) => {
    return (
        <button onClick={funOnclick} className={type} id={id} name={nombre}>
            {children}
        </button>
    )
}

export default Btn