import "./styles.scss"

const Index = () => {

    return (
        <section className="cobertura">
            <div className="carta">
                <h2>NUESTRA COBERTURA</h2>
                <hr></hr>
                <p>{"Nuestros servicio de transporte son conocidos por su calidad, seguridad y ser asequibles, es por ello que nos enorgullecemos de ofrecer lo mejor a nivel de servicio y calidad en los varias de las ciudades mas importantes del pais."}
                </p>
            </div>

        </section>
    )
}

export default Index
