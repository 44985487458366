import "./styles.scss";

const Aliados = () => {
	return (
		<>
			<section className="aliados">
				<div className="aliados_banner"></div>
				<div className="aliados_container">
					<div className="lista">
						<h2>Requisitos para transportar con Forzakargo</h2>
						<div name="0">
							<p>
								Para TRANSPORTES FORZAKARGO SAS es muy importante contar con su participación
								para el desarrollo de sus operaciones en la línea de carga seca;
								Contamos con ofertas de carga en las principales ciudades y
								puertos del país para tractomulas, Dobletroques, minimulas,
								sencillos y turbos.
							</p>
							<p>
								Conscientes de la importancia que tienen los conductores para 
								TRANSPORTS FORZAKARGO SAS, día a día trabajamos para prestar un buen
								servicio a nuestros aliados de negocio, con el fin de continuar
								siendo la empresa preferida para ellos, con asignación oportuna
								de viajes, fletes acordes al mercados, pago de anticipos y
								saldos a tiempo y atención a sus novedades en ruta a nivel
								nacional.
							</p>
						</div>
					</div>

					<div className="lista icon1">
						<h3>Conductor</h3>
						<ul>
							<li>Cedula(No puede ser contraseña)</li>
							<li>Licencia de conducción Vigente</li>
							<li>Planilla de Pago seguridad social(EPS ARL)</li>
							<li>Dirección y teléfono de la residencia</li>
							<li>Referencia familiar con teléfono</li>
							<li>2 Referencias de empresas (Contacto – Cargo – Teléfono)</li>
							<li>Cuenta Bancaria</li>
							<li>
								Experiencia mínima de 2 años para el caso de vehículos
								articulados
							</li>
							<li>Para transporte de líquidos Curso HSE</li>
							<li>Copia del RUT</li>
						</ul>
					</div>

					<div className="lista icon2">
						<h3>Vehículo</h3>
						<ul>
							<li>Licencia de transito</li>
							<li>Seguro Obligatorio SOAT vigente</li>
							<li>Revisión Tecno mecánica y de gases</li>
							<li>
								Vehículos con modelos superiores a 30 años deben estar
								repotenciados a 10 años o menos.
							</li>
							<li>
								Tiquete de báscula del peso vacío del vehículo no mayor a una
								semana de antigüedad.
							</li>
							<li>Satelital Vehículo (GPS)</li>
							<li>Contrato de compraventa (si aplica)</li>
						</ul>
					</div>
				</div>
			</section>
		</>
	);
};

export default Aliados;
