import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss"
import MiniService from "../MiniService/index";

const Index = () => {

    const miniServicesData = [
        {
            id: 1,
            img: "images/services/icon_s1.png",
            title: "Transporte de mercancias",
            nombre: "s1",
            description: "Transporte de productos terminados con unidades de empaque definidas, en un solo tipo de vehículo desde un punto de origen y hasta 2 entregas en destino, Transporte de carga sólida que se moviliza en grandes cantidades sin empaquetar y que usualmente es depositada por medio de silos, en volcos y carrocerías como: Carbón, Chatarra, Maíz y Trigo, Transporte de insumos, materia primas, productos intermedios (carga seca) diferente a un producto terminado cuya unidad de empaque normalmente es carga suelta, big bag, pallet, entre otras."
        },
        {
            id: 2,
            img: "images/services/icon_s2.png",
            title: "Transporte de consolidados",
            nombre: "s2",
            description: "Transporte de cupos completos y consolidación, hacia y desde los diferentes puertos (Cartagena, Barranquilla, Santa Marta y Buenaventura)."
        },
        {
            id: 3,
            img: "images/services/icon_s3.png",
            title: "Transporte de Contenedores",
            nombre: "s3",
            description: "Transporte de contenedores de 20’, 40’ desde los principales puertos del país (Cartagena, Buenaventura, Barranquilla y Santa Marta) hacia el interior y desde el interior hacia puertos."
        },
        {
            id: 4,
            img: "images/services/icon_s4.png",
            title: "Transporte de Última milla",
            nombre: "s4",
            description: "Conscientes de los planes de ordenamiento territorial POT y de los cambios en los modelos de distribución de las empresas, ofrecemos el servicio de transporte de última milla en las principales ciudades del país y sus municipios cercanos."
        },
        {
            id: 5,
            img: "images/services/icon_s5.png",
            title: "Operaciones IN HOUSE",
            nombre: "s5",
            description: "Por medio de esta modalidad de servicio, hacemos presencia dentro de las instalaciones de nuestros clientes, a fin de sumar sinergias y conocer las necesidades de este, entregando de primera mano todo el conocimiento y la experticia de nuestro funcionarios; quienes con una dependencia laboral directa TRANSPORTES FORZAKARGO SAS presta toda la disponibilidad de tiempo y recursos en pro de la solución logística del asociado de negocio."
        },
        {
            id: 6,
            img: "images/services/icon_s6.png",
            title: "Administración de flotas",
            nombre: "s6",
            description: "Ofrecemos a nuestros clientes el análisis de sus operaciones con flota propia, optimización, desarrollo de corredores y administración de costos y personal, permitiendo que estas sean rentables para sus operaciones."
        },
    ]

    const open_tab = (id) => {
        var i, tabcontent;

        tabcontent = document.getElementsByClassName("miniService_phone");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = tabcontent[i].className.replace(" active", " no_active");
        }

        document.getElementById(id).className = "miniService_phone active"
    }

    return (
        <section className="miniServices">
            {miniServicesData.map((e, i) => {
                return <>
                    <MiniService open_tab={open_tab} key={i} data={e} />
                </>
            })}
        </section>
    )
}

export default Index
