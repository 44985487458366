import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Mapa = () => {
	const data = [
		{
			id: 1,
			name: "BOGOTÁ",
			mapa: "images/maps/bogota.jpg",
		},
		{
			id: 2,
			name: "ITAGUI",
			mapa: "images/maps/medellin.jpg",
		},
		{
			id: 3,
			name: "CARTAGENA",
			mapa: "images/maps/cartagena.jpg",
		},
		{
			id: 4,
			name: "BUENAVENTURA",
			mapa: "images/maps/buena.jpg",
		},
	];

	const [actData, setActData] = useState(data[0]);
	const [tmot, setTmot] = useState(null);

	const cambiar_mapa = (element) => {
		if (tmot) {
			clearTimeout(tmot);
		}
		const mapa = data.find((e) => "item_" + e.id == element.target.id);
		setActData(mapa);
	};

	const cambiar_mapa_auto = (num) => {
		const time_out = setTimeout(() => {

			const buscar = "item_" + num;
			const item = document.getElementById(buscar);
			if (item) {
				item.click();
			}

			if (num >= data.length) {
				cambiar_mapa_auto(1);
			} else {
				cambiar_mapa_auto(num + 1);
			}
		}, 2000);
		setTmot(time_out);
	};

	useEffect(() => {
		cambiar_mapa_auto(1);
	}, []);

	return (
		<>
			<section id="mapa">
				<div className="covertura_mapas">
					{data.map((e) => {
						return (
							<div
								className="mapa_img"
								style={{ display: actData.id == e.id ? "block" : "none" }}
								onClick={cambiar_mapa}
								id={"mapa_" + e.id}
							>
								<Link to={"/oficinas"}>
									<img src={e.mapa} alt={e.name} />
								</Link>
							</div>
						);
					})}
				</div>

				<div className="covertura_lista">
					<ul>
						{data.map((e) => {
							return (
								<li
									onClick={cambiar_mapa}
									className={actData.id == e.id ? "active" : ""}
									id={"item_" + e.id}
								>
									{e.name}
								</li>
							);
						})}
					</ul>
				</div>
			</section>
		</>
	);
};

export default Mapa;
