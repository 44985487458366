import { useEffect } from "react";
import Layout from "../components/Layout"
import Nosotro from "../components/Nosotros"

const Nosotros = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    return (
        <Layout actualPage="nosotros">
            <div id="nosotros">
                <Nosotro />
            </div>
        </Layout>
    )
}

export default Nosotros