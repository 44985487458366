import Layout from "../components/Layout"
import MiniServices from "../components/MiniServices"
import Covertura from "../components/Covertura"
import Contratar from "../components/Contratar"
import Mapa from "../components/Mapa"
import Carrousel from "../components/Carrousel"
import { useEffect } from "react"

const Inicio = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    return (
        <Layout>
            <div id="inicio">
                <Carrousel />
                <div className="container inicio_page">
                    <MiniServices />
                    <Covertura />
                    <Mapa />
                    <Contratar />
                </div>
            </div>
        </Layout>
    )
}

export default Inicio