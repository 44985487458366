import { Link } from "react-router-dom"
import BtnIcon from "../BtnIcon"
import "./styles.scss"

const Footer = () => {

    const abrirConsultarGuia = () => {
        window.open("http://servicioserp.com/index.php/utilidad/localizador/38")
    }

    const dataSitiosInteres = [
        {
            name: "Nosotros",
            url: "/nosotros",
        },
        {
            name: "Oficinas",
            url: "/oficinas",
        },
        {
            name: "Cobertura",
            url: "/oficinas",
        },
        {
            name: "Sé nuestro aliado",
            url: "/aliados",
        },
        {
            name: "Servicios",
            url: "/servicios",
        },
        {
            name: "Contactanos",
            url: "/contacto",
        },
        {
            name: "E-mail",
            url: "https://mail.forzakargo.com",
        },
    ]

    const dataRedes = [
        {
            img: "./images/fb.png",
            url: "",
            alt: "img redes"
        },
        {
            img: "./images/ins.png",
            url: "",
            alt: "img redes"
        },
    ]

    return (
        <footer id="inicio" className="footer">
            {/* <BtnIcon funOnclick={abrirConsultarGuia} id="btn_consultar_guia" nombre="btn_consultar_guia" type="btn_consultar_guia">CONSULTAR GUIA</BtnIcon> */}
            <div id="interes" className="contFooter">
                <h4>SITIOS DE INTERES</h4>
                {dataSitiosInteres.map((e, i) => {

                    if (e.name == "E-mail") {
                        return <a target="_blank" href={e.url}>{e.name}</a>
                    }

                    return <Link key={i} to={e.url}>
                        {e.name}
                    </Link>
                })}
            </div>
            <div id="redes" className="contFooter">
                <h4>NUESTRAS REDES SOCIALES</h4>
                <div>
                    {
                        dataRedes.map((e, i) => {
                            return <Link key={i} to={e.url}>
                                <img src={e.img} alt={e.alt}></img>
                            </Link>
                        })
                    }
                </div>
            </div>
            <div id="sobre" className="contFooter">
                <h4>SOBRE NOSOTROS</h4>
                <p>
                    Es una empresa constituida en el 2004 con la razón social Rodriguez y Benavides Ltda. Con el objeto social: Transporte de carga a nivel nacional, en la modalidad de carga líquida, solida, granel, masiva, semi-masiva, refrigerada, seca y transporte mixto, hoy TRANSPORTES FORZAKARGO S.A.S.
                </p>
                <div className="logoImg">
                    <img src="./images/logo-p-blanco.png" alt="Logo"></img>
                </div>
            </div>
        </footer>
    )
}

export default Footer