import Layout from "../components/Layout"
import Contacto from "../components/Contacto"
import { useEffect } from "react";

const Index = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    return (
        <Layout actualPage="contacto">
            <div id="contacto">
                <Contacto/>
            </div>
        </Layout>
    )
}

export default Index