import { useEffect } from "react";
import Layout from "../components/Layout"

const InicioSesion = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    return (
        <Layout actualPage="inicio_sesion">
            <div id="inicio_sesion">
                InicioSesion
            </div>
        </Layout>
    )
}

export default InicioSesion