import { BrowserRouter, Route, Switch } from "react-router-dom";
import InicioSesion from "./pages/InicioSesion";
import Servicios from "./pages/Servicios";
import Contacto from "./pages/Contacto";
import Nosotros from "./pages/Nosotros";
import Oficinas from "./pages/Oficinas";
import Aliados from "./pages/Aliados";
import Inicio from "./pages/Inicio";
import "./App.scss";

const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/inicio_sesion" component={InicioSesion} />
				<Route path="/servicios" component={Servicios} />
				<Route path="/contacto" component={Contacto} />
				<Route path="/nosotros" component={Nosotros} />
				<Route path="/oficinas" component={Oficinas} />
				<Route path="/aliados" component={Aliados} />
				<Route path="/" component={Inicio} />
				<Route path="*" render={()=>{
					return "No encontrada"
				}}/>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
