import "./styles.scss";
import Btn from "../Btn";
import { Link } from "react-router-dom";

const Index = ({ data, iterator }) => {
	return (
		<div className="itemContactar col-4">
			<div className="imgContainer">
				<img src={data.img} alt="icon" />
			</div>
			<h4>{data.title}</h4>
			{iterator == 1 ? (
				<Link to="/contacto">
					<Btn>{data.txtBtn}</Btn>
				</Link>
			) : (
				<Link to="/aliados">
					<Btn>{data.txtBtn}</Btn>
				</Link>
			)}
		</div>
	);
};

export default Index;
