import { Link } from "react-router-dom";
import Btn from "../Btn/index";
import "./styles.scss";

const Service = ({ data }) => {
	return (
		<div className="services">
			<div className="myCard" id={data.id}>
				<div className="imagen">
					<img src={data.img} alt="imagen servicio" />
				</div>
				<div className="cardDescription">
					<h1>{data.name}</h1>
					<p>{data.description}</p>
					<div className="botones">
						<div className="social">
							<Link to="/contacto">
								<i className="fa fa-envelope-o fa-2x"></i>
							</Link>
						</div>
						<div className="animacion_servicio">
							<img src={data.img_icon} alt="imagen"></img>
						</div>
						<div className="saberMas">
							<Link to={data.url}>
								<Btn type={"btn_2"}>Saber más</Btn>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Service;
