import "./styles.scss";
import { useFormik } from "formik";
import Btn from "../Btn";
import emailjs from "emailjs-com";
import { useState } from "react";

const Contacto = () => {

	const [mensaje, setMensaje] = useState("")

	const enviarEmail = (data) => {
		emailjs
			.send(
				"service_qz039ee",
				"template_2xj6v4c",
				{
					asunto: data.asunto,
					nombre: data.nombre,
					email: data.email,
					tel: data.tel,
					mensaje: data.mensaje,
					copia_a: data.email,
				},
				"Z2XY-RI5Uka74O9rW"
			)
			.then(
				(response) => {
					console.log("SUCCESS!", response.status, response.text);
					setMensaje("¡Genial! hemos recibido tu mensaje")
					formik.resetForm()
				},
				(err) => {
					console.log("FAILED...", err);
					setMensaje("¡Ups! no se envio el mensaje, te invitamos a contactar por whatapp")
					formik.resetForm()
				}
			);
	};

	const formik = useFormik({
		initialValues: {
			nombre: "",
			email: "",
			tel: "",
			asunto: "",
			mensaje: "",
		},
		validate: (values) => {
			const errors = {};
			if (!values.nombre) {
				errors.nombre = "El nombre es requerido";
			} else if (values.nombre.length < 2) {
				errors.nombre = "Nombre no válido";
			}

			if (!values.email) {
				errors.email = "El correo electrónico es requerido";
			} else if (values.email.length < 4) {
				errors.email = "Correo electrónico no válido";
			}

			if (!values.tel) {
				errors.tel = "El teléfono es requerido";
			} else if (values.tel.toString().length < 6) {
				errors.tel = "teléfono no válido";
			}

			if (!values.asunto) {
				errors.asunto = "El asunto es requerido";
			} else if (values.asunto.length < 3) {
				errors.asunto = "Asunto no válido";
			}

			if (!values.mensaje) {
				errors.mensaje = "El mensaje es requerido";
			} else if (values.mensaje.length < 3) {
				errors.mensaje = "Mensaje no válido";
			}
			return errors;
		},
		onSubmit: (values) => {
			enviarEmail(values);
		},
	});

	return (
		<section className="contacto_cont">
			<div className="bienvenida_contacto"></div>

			<div className="cont_formulario">
				<form onSubmit={formik.handleSubmit}>
					<h2>Formulario</h2>
					<p>
						Envianos cualquier duda que tengas y te contactaremos lo mas pronto
						posible.
					</p>
					<p>
						Los campos con <small>*</small> son requeridos.
					</p>

					<label htmlFor="nombre">
						Nombre<small> *</small>
					</label>
					<input
						id="nombre"
						name="nombre"
						type="text"
						onChange={formik.handleChange}
						value={formik.values.nombre}
					/>
					{formik.errors.nombre ? <small>{formik.errors.nombre}</small> : null}

					<label htmlFor="email">
						Correo electrónico<small> *</small>
					</label>
					<input
						id="email"
						name="email"
						type="email"
						onChange={formik.handleChange}
						value={formik.values.email}
					/>
					{formik.errors.email ? <small>{formik.errors.nombre}</small> : null}

					<label htmlFor="tel">
						Teléfono<small> *</small>
					</label>
					<input
						id="tel"
						name="tel"
						type="number"
						onChange={formik.handleChange}
						value={formik.values.tel}
					/>
					{formik.errors.tel ? <small>{formik.errors.tel}</small> : null}

					<label htmlFor="asunto">
						Asunto<small> *</small>
					</label>
					<input
						id="asunto"
						name="asunto"
						type="text"
						onChange={formik.handleChange}
						value={formik.values.asunto}
					/>
					{formik.errors.asunto ? <small>{formik.errors.asunto}</small> : null}

					<label htmlFor="mensaje">
						Mensaje<small> *</small>
					</label>
					<textarea
						id="mensaje"
						name="mensaje"
						onChange={formik.handleChange}
						value={formik.values.mensaje}
					/>
					{formik.errors.mensaje ? (
						<small>{formik.errors.mensaje}</small>
					) : null}

					<Btn type="btn_2">Enviar</Btn>
					<label>{mensaje}</label>
				</form>
				<div className="imagen_form">
					<a
						href="https://www.freepik.es/vectores/negocios"
						style={{ display: "none" }}
					>
						Vector de Negocios creado por pikisuperstar - www.freepik.es
					</a>
				</div>
			</div>
		</section>
	);
};

export default Contacto;
