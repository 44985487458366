import { useRef } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Burbuja = () => {
	const ref_burbuja_wpp = useRef(null);
	const ref_burbuja_email = useRef(null);

	const mostrar_burbujas = (e) => {
		ref_burbuja_email.current.classList.toggle("burbuja_email_open");
		ref_burbuja_wpp.current.classList.toggle("burbuja_wpp_open");
	};

	return (
		<>
			<div className="burbujas">
				<Link to="/contacto">
					<div
						className="burbuja_email"
						id="burbuja_email"
						ref={ref_burbuja_email}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="40"
							height="40"
							viewBox="0 0 172 172"
							style={{ fill: "#000000" }}
						>
							<g
								fill="none"
								fill-rule="nonzero"
								stroke="none"
								stroke-width="1"
								stroke-linecap="butt"
								stroke-linejoin="miter"
								stroke-miterlimit="10"
								stroke-dasharray=""
								stroke-dashoffset="0"
								font-family="none"
								font-weight="none"
								font-size="none"
								text-anchor="none"
								style={{ mixBlendMode: "normal" }}
							>
								<path d="M0,172v-172h172v172z" fill="none"></path>
								<g fill="#ffffff">
									<path d="M32.25,26.875c-11.81067,0 -21.5,9.68933 -21.5,21.5v80.625c0,8.84171 7.28329,16.125 16.125,16.125h26.875v-53.58203l32.25,24.30298l32.25,-24.30298v53.58203h26.875c8.84171,0 16.125,-7.28329 16.125,-16.125v-80.625c0,-11.81067 -9.68933,-21.5 -21.5,-21.5c-4.82069,0 -9.3356,1.59993 -12.94409,4.2937l-0.0105,0.0105l-40.79541,30.72778l-40.78491,-30.72778v0.0105c-3.62222,-2.73469 -8.15282,-4.3147 -12.96509,-4.3147zM32.25,37.625c2.47623,0 4.71351,0.80206 6.48779,2.1416l4.26221,3.2124v27.00097l-21.5,-16.19849v-5.40649c0,-6.00208 4.74792,-10.75 10.75,-10.75zM139.75,37.625c6.00208,0 10.75,4.74792 10.75,10.75v5.39599l-21.5,16.19849v-27.00098l4.24121,-3.19141c1.77726,-1.32673 4.09473,-2.1521 6.50879,-2.1521zM53.75,51.073l32.25,24.29248l32.25,-24.29248v27.00098l-32.25,24.30298l-32.25,-24.30298zM21.5,67.22949l21.5,16.20899v50.93652h-16.125c-3.03704,0 -5.375,-2.33796 -5.375,-5.375zM150.5,67.22949v61.77051c0,3.03704 -2.33796,5.375 -5.375,5.375h-16.125v-50.93652z"></path>
								</g>
							</g>
						</svg>
					</div>
				</Link>
				<Link
					to={window.location}
					onClick={() => {
						window.open(
							`https://api.whatsapp.com/send?phone=573104477161&text=Hola! me llamo `
						);
					}}
				>
					<div className="burbuja_wpp" id="burbuja_wpp" ref={ref_burbuja_wpp}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="40"
							height="40"
							viewBox="0 0 172 172"
							style={{ fill: "#000000" }}
						>
							<g
								fill="none"
								fill-rule="nonzero"
								stroke="none"
								stroke-width="1"
								stroke-linecap="butt"
								stroke-linejoin="miter"
								stroke-miterlimit="10"
								stroke-dasharray=""
								stroke-dashoffset="0"
								font-family="none"
								font-weight="none"
								font-size="none"
								text-anchor="none"
								style={{ mixBlendMode: "normal" }}
							>
								<path d="M0,172v-172h172v172z" fill="none"></path>
								<g fill="#ffffff">
									<path d="M86.08399,14.33333c-39.45967,0 -71.58235,32.09502 -71.59668,71.55469c-0.00717,12.61333 3.29655,24.92701 9.56022,35.77734l-9.71419,36.00131l37.49902,-8.86035c10.45617,5.70467 22.22697,8.69922 34.20963,8.70638h0.028c39.4525,0 71.56118,-32.10219 71.58268,-71.55469c0.01433,-19.12784 -7.42377,-37.11191 -20.9401,-50.64258c-13.51633,-13.5235 -31.47925,-20.97493 -50.62858,-20.9821zM86.06999,28.66667c15.308,0.00717 29.69396,5.97554 40.50846,16.78288c10.8145,10.82167 16.75522,25.2008 16.74089,40.49447c-0.01433,31.56199 -25.68702,57.23535 -57.26335,57.23535c-9.55317,-0.00717 -19.01607,-2.40588 -27.35091,-6.95671l-4.8291,-2.63151l-5.33301,1.25976l-14.10937,3.33138l3.44336,-12.79362l1.55371,-5.73894l-2.96745,-5.15104c-5.00233,-8.65733 -7.64974,-18.55584 -7.64258,-28.61067c0.01433,-31.54767 25.69452,-57.22135 57.24935,-57.22135zM60.7487,52.85417c-1.19683,0 -3.13195,0.44792 -4.77311,2.23958c-1.64117,1.7845 -6.27083,6.10656 -6.27083,14.90723c0,8.80067 6.41081,17.30772 7.30664,18.50455c0.88867,1.18967 12.37448,19.82031 30.55632,26.98698c15.10733,5.9555 18.17568,4.78017 21.45801,4.47917c3.28233,-0.29383 10.58909,-4.31825 12.07975,-8.49641c1.49066,-4.17817 1.49414,-7.77226 1.0498,-8.51042c-0.44433,-0.74533 -1.6377,-1.18978 -3.42936,-2.08561c-1.7845,-0.89583 -10.57856,-5.21409 -12.21973,-5.80892c-1.64117,-0.59483 -2.84158,-0.89583 -4.03125,0.89583c-1.18967,1.79167 -4.60861,5.80903 -5.65494,6.9987c-1.04633,1.19683 -2.08561,1.35775 -3.87728,0.46191c-1.79167,-0.903 -7.55232,-2.79668 -14.38932,-8.88835c-5.31767,-4.73717 -8.90582,-10.58203 -9.95215,-12.37369c-1.03917,-1.7845 -0.09798,-2.76466 0.79785,-3.65332c0.80267,-0.80267 1.77767,-2.08908 2.6735,-3.13542c0.88867,-1.04633 1.19683,-1.79178 1.79167,-2.98145c0.59483,-1.18966 0.29036,-2.23958 -0.15398,-3.13541c-0.44433,-0.89583 -3.92397,-9.7292 -5.51497,-13.26953c-1.34017,-2.97417 -2.75558,-3.04326 -4.03125,-3.09342c-1.03917,-0.043 -2.2257,-0.04199 -3.41536,-0.04199z"></path>
								</g>
							</g>
						</svg>
					</div>
				</Link>
				<div className="burbuja" id="burbuja" onClick={mostrar_burbujas}>
					<svg
						version="1.0"
						xmlns="http://www.w3.org/2000/svg"
						width="50"
						height="50"
						viewBox="0 0 512.000000 512.000000"
						preserveAspectRatio="xMidYMid meet"
						style={{ fill: "#000000" }}
					>
						<g
							transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
							fill="#000000"
							stroke="none"
							style={{ mixBlendMode: "normal" }}
						>
							<path
								d="M1143 4651 c-55 -14 -230 -119 -316 -190 -231 -191 -398 -469 -462
-772 -38 -175 -35 -451 5 -648 41 -202 99 -369 195 -561 75 -150 585 -1027
663 -1139 337 -485 797 -780 1263 -809 295 -19 554 40 789 180 87 52 110 71
135 112 29 48 30 54 29 160 0 96 -4 121 -28 186 -41 108 -93 207 -186 355
-139 220 -217 311 -330 384 -87 57 -153 74 -254 69 -93 -5 -141 -24 -306 -121
-100 -59 -108 -62 -170 -62 -81 0 -157 35 -223 104 -29 30 -125 185 -284 461
-132 228 -249 431 -259 450 -26 51 -32 197 -9 255 22 58 51 84 171 155 l98 58
17 -27 c26 -43 1073 -861 1153 -901 64 -32 77 -35 155 -34 70 0 96 5 141 26
35 16 324 236 780 594 399 314 731 573 738 578 9 6 12 -183 12 -929 l0 -936
-25 -24 -24 -25 -518 0 c-333 0 -532 -4 -556 -11 -75 -21 -101 -112 -48 -171
l29 -33 564 0 c556 0 564 0 610 22 66 30 132 95 157 153 l21 50 -2 1057 -3
1058 -27 47 c-34 56 -92 104 -158 129 -48 18 -101 19 -1453 19 l-1403 0 -88
173 c-108 211 -178 318 -275 420 -107 112 -222 162 -318 138z m113 -239 c79
-57 165 -179 277 -392 88 -168 128 -285 130 -375 2 -117 -8 -127 -242 -261
-150 -86 -212 -164 -247 -311 -20 -85 -14 -225 14 -303 18 -50 487 -870 553
-967 63 -91 190 -178 304 -208 77 -20 192 -19 252 1 26 9 107 50 178 92 153
88 202 99 275 62 94 -48 196 -168 328 -387 91 -151 140 -261 156 -352 12 -74
13 -74 -108 -140 -286 -158 -674 -173 -987 -37 -282 121 -548 353 -748 650
-65 97 -577 979 -633 1091 -238 475 -272 969 -93 1340 58 120 114 196 229 310
82 82 134 124 201 162 50 29 97 53 106 53 9 0 34 -13 55 -28z m3278 -711 c-13
-12 -1366 -1074 -1432 -1124 -63 -48 -129 -60 -180 -33 -17 9 -266 200 -552
425 -286 225 -529 415 -540 422 -19 14 -19 15 11 76 26 51 32 78 37 153 l5 90
1330 0 c776 0 1327 -4 1321 -9z"
							/>
						</g>
					</svg>
				</div>
			</div>
		</>
	);
};

export default Burbuja;
