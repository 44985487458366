import React, { useState } from 'react';
import "./styles.scss"
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

const frase = "TRANSPORTE A NIVEL NACIONAL DE LA MEJOR CALIDAD"
const sub_frase = "Estamos al servicio de todos"
const items = [
    {
        src: 'images/c1.jpg',
        altText: "imagen",
        caption: frase,
        sub_caption: sub_frase,
    },
    {
        src: 'images/c3.jpg',
        altText: "imagen",
        caption: frase,
        sub_caption: sub_frase,
    },
    {
        src: 'images/c2.jpg',
        altText: "imagen",
        caption: frase,
        sub_caption: sub_frase,
    },

];

const Carrousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img src={item.src} alt={item.altText} width={"100%"} height={"auto"} />
                <CarouselCaption captionText={item.sub_caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
}

export default Carrousel;