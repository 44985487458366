import "./styles.scss";
import Btn from "../Btn";
import { Link } from "react-router-dom";

const Index = ({ data, open_tab }) => {
	return (
		<>
			<div
				onClick={(e) => {
					open_tab(data.id);
				}}
				className="miniService"
				name="nombre"
			>
				<Link to="/servicios">
					<div className="imgCircleBg">
						<img src={data.img} alt="icon" />
					</div>
				</Link>
				<h2>{data.title}</h2>
				<Link to="servicios">
					<Btn>Mas información</Btn>
				</Link>
			</div>
			<div className="miniService_phone no_active" id={data.id}>
				<p name="nombre">{data.description}</p>
				<Link to="/servicios">
					<Btn>Mas información</Btn>
				</Link>
			</div>
		</>
	);
};

export default Index;
