import { useEffect } from "react";
import Layout from "../components/Layout"
import Oficinas from "../components/oficinas"

const Index = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    return (
        <Layout actualPage="oficinas">
            <div id="oficinas" className="container">
                <Oficinas/>
            </div>
        </Layout>
    )
}

export default Index