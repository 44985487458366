import Service from "../Service"
import "./styles.scss"

const Services = () => {

    const dataServices = [
        {
            name: "Transporte de mercancías",
            id: 1,
            url: "/contacto",
            img: "/images/services/s1.jpg",
            img_icon:"/images/services/icon_s1.png",
            description: "Transporte de productos terminados con unidades de empaque definidas, en un solo tipo de vehículo desde un punto de origen y hasta 2 entregas en destino, Transporte de carga sólida que se moviliza en grandes cantidades sin empaquetar y que usualmente es depositada por medio de silos, en volcos y carrocerías como: Carbón, Chatarra, Maíz y Trigo, Transporte de insumos, materia primas, productos intermedios (carga seca) diferente a un producto terminado cuya unidad de empaque normalmente es carga suelta, big bag, pallet, entre otras."
        },
        {
            name: "Transporte de consolidados",
            id: 2,
            url: "/contacto",
            img: "/images/services/s2.jpg",
            img_icon:"/images/services/icon_s2.png",
            description: "Transporte de cupos completos y consolidación, hacia y desde los diferentes puertos (Cartagena, Barranquilla, Santa Marta y Buenaventura)."
        },
        {
            name: "Transporte de Contenedores",
            id: 3,
            url: "/contacto",
            img: "/images/services/s3.jpg",
            img_icon:"/images/services/icon_s3.png",
            description: "Transporte de contenedores de 20’, 40’ desde los principales puertos del país (Cartagena, Buenaventura, Barranquilla y Santa Marta) hacia el interior y desde el interior hacia puertos."
        },
        {
            name: "Transporte de Última milla",
            id: 4,
            url: "/contacto",
            img: "/images/services/s4.jpg",
            img_icon:"/images/services/icon_s4.png",
            description: "Conscientes de los planes de ordenamiento territorial POT y de los cambios en los modelos de distribución de las empresas, ofrecemos el servicio de transporte de última milla en las principales ciudades del país y sus municipios cercanos."
        },
        {
            name: "Operaciones IN HOUSE",
            id: 5,
            url: "/contacto",
            img: "/images/services/s5.jpg",
            img_icon:"/images/services/icon_s5.png",
            description: "Por medio de esta modalidad de servicio, hacemos presencia dentro de las instalaciones de nuestros clientes, a fin de sumar sinergias y conocer las necesidades de este, entregando de primera mano todo el conocimiento y la experticia de nuestro funcionarios; quienes con una dependencia laboral directa TRANSPORTES FORZAKARGO SAS presta toda la disponibilidad de tiempo y recursos en pro de la solución logística del asociado de negocio."
        },
        {
            name: "Administración de flotas",
            id: 6,
            url: "/contacto",
            img: "/images/services/s6.jpg",
            img_icon:"/images/services/icon_s6.png",
            description: "Ofrecemos a nuestros clientes el análisis de sus operaciones con flota propia, optimización, desarrollo de corredores y administración de costos y personal, permitiendo que estas sean rentables para sus operaciones."
        },
    ]

    return (
        <section className="servicesCont">

            <div className="bienvenida_servicios">
                <p>
                    <h2>SERVICIOS DE <br></br> <strong>CALIDAD</strong> RAPIDOS Y SEGUROS</h2>
                </p>
            </div>
            {
                dataServices.map((e) => {
                    return <Service data={e} />
                })
            }
        </section>
    )
}

export default Services