import { useEffect } from "react"
import Burbuja from "../Burbuja"
import Footer from "../Footer"
import Navbar from "../Navbar"
import "./styles.scss"

const Layout = ({ children, actualPage = "" }) => {

    useEffect(() => {
        mostrar_burbujas()
    })

    const mostrar_burbujas = () => {
        setTimeout(() => {
            document.getElementById("burbuja").click()
        }, 10000);
    }

    return (
        <>
            <Navbar actualPage={actualPage} />
            <main>
                {children}
            </main>
            <Burbuja />
            <Footer></Footer>
        </>
    )
}

export default Layout