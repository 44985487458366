import Btn from "../Btn/index"
import "./styles.scss"

const Nosotros = () => {

    return (
        <section className="nosotros_cont container">
            <div className="bienvenida_nosotros">
                <p>
                    {/*<h2><strong>LO TRASPORTAMOS COMO SI FUERA NUESTRO</strong></h2>*/}
                </p>
            </div>
            <div className="nosotros_body">
                <div className="nuetra_empresa">
                    <div>
                        <h2>Nuestra empresa <i class="fas fa-trophy trofeo"></i></h2>
                        <p>Es una empresa constituida en el 2.004 con la razón social Rodriguez y Benavides Ltda. Con el objeto social: Transporte de carga a nivel nacional, en la modalidad de carga líquida, solida, granel, masiva, semi-masiva, refrigerada, seca y transporte mixto, hoy TRANSPORTES FORZAKARGO S.A.S.</p>
                    </div>
                </div>
                <div className="mision">
                    <div>
                        <h4>Misión</h4>
                        <p>Contribuir con el desarrollo empresarial , económico y social del país, mediante la participación en el sector de transporte terrestre de carga, ofreciendo a nuestros clientes innovación en calidad , servicio y tecnología; desarrollando aliados estratégicos que nos permitan satisfacer los requerimientos de nuestros clientes en su cadena de suministro y la satisfacción de nuestros accionistas, empleados y proveedores.</p>
                    </div>
                </div>
                <div className="vision">
                    <div>
                        <h4>Visión</h4>
                        <p>Nuestra Empresa se posicionará en el 2025 como la mejor y más reconocida en el servicio de transporte de carga masiva y semi masiva en el país, ofreciendo altos estándares de calidad y servicio con el decidido apoyo de un equipo humano idóneo y con una plataforma tecnológica de última generación, respondiendo así a las expectativas de una empresa inteligente.</p>
                    </div>
                </div>

                <div className="mas_nosotros">
                    <img alt="imagen" src="images/nosotros2.png"></img>
                    <label htmlFor="">Te estamos esperando.</label>
                    <Btn>CONTÁCTANOS</Btn>
                </div>

            </div>
        </section>
    )
}

export default Nosotros