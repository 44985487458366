import { useEffect } from "react";
import Aliados from "../components/Aliados";
import Layout from "../components/Layout/index";

const Index = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout actualPage="aliados">
			<div id="aliados" className="container">
				<Aliados />
			</div>
		</Layout>
	);
};

export default Index;
